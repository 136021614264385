import { useEffect, useRef, useState } from 'react';
import { httpService } from 'api/api';
import ApiToastHandle from 'containers/ApiToastHandle';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { getToolsInserted } from 'store/actions/addTools';
import { getDrillerLogs } from 'store/actions/drillerMenu';
import ApiErrorHandler from 'utils/ApiErrorHandler';
import { getAutoDepth } from 'store/actions/general';
import { Modal } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

const PipeController = () => {
  const dispatch = useDispatch();
  const { toolInserted } = useSelector((state) => state.addTools);
  const realTimeData = useSelector((state) => state.liveData.data);
  const [data, setData] = useState(toolInserted);
  const [openPipeModal, setOpenPipeModal] = useState(false);
  const [modalTxt, setModalTxt] = useState('adding');
  const autoTally = realTimeData?.boolAutoBHA ? JSON.parse(realTimeData?.boolAutoBHA) : false;
  const { autoDepthstatus } = useSelector((state) => state.general);

  const confirmButtonRef = useRef(null);

  useEffect(() => {
    dispatch(getAutoDepth('auto_depth'));
    dispatch(getToolsInserted());
    dispatch(getDrillerLogs());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setData(toolInserted);
  }, [toolInserted]);

  useEffect(() => {
    // const { pathname } = location;

    // eslint-disable-next-line consistent-return
    const handleKeyDown = (event) => {
      const { keyCode } = event;

      // if (pathname.includes('driller-menu')) return;

      if (keyCode === 113) {
        event.preventDefault();
        event.stopPropagation();
        setModalTxt('adding');
        setOpenPipeModal(true);
      } else if (keyCode === 114) {
        event.preventDefault();
        event.stopPropagation();
        setModalTxt('removing');
        setOpenPipeModal(true);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [data, autoTally, autoDepthstatus?.auto_depth]);

  const handleF2Key = () => {
    const latestDeactiveItem = _.findLast(data, { active: false });
    if (latestDeactiveItem) {
      updateTools(latestDeactiveItem.id, { active: true, xid: latestDeactiveItem.index });
    } else {
      ApiToastHandle(400,'All the strings are active!', 'warning');
    }
  };

  const handleF3Key = () => {
    const firstActiveItem = _.find(data, { active: true });
    if (firstActiveItem) {
      updateTools(firstActiveItem.id, { active: false, xid: firstActiveItem.index });
    } else {
      ApiToastHandle(400,'All the strings are inactive!', 'warning');
    }
  };

  // eslint-disable-next-line consistent-return
  const updateTools = (id, data, isTally) => {
    if (isTally) {
      return ApiToastHandle(400, `Auto Tally is ON you can't add/remove pipe when it's ON`, 'warning');
    }
    
    const response = () => {
      ApiToastHandle(200, 'tool updated successfully', 'success');
      dispatch(getAutoDepth('auto_depth'));
      dispatch(getToolsInserted());
      // dispatch(getDrillerLogs());
      setModalTxt('adding');
      setOpenPipeModal(false);
    };

    const error = (err) => {
      ApiErrorHandler(err);
    };

    httpService.updateTools(response, error, id, data);
  };

  const checkAutoTallyAndAutoDepth = () => {
    if (autoTally) {
      ApiToastHandle(410, 'Auto tally is On!', 'warning');
      return false;
    }
    if (Number(autoDepthstatus.auto_depth) === 1) {
      ApiToastHandle(410, 'Auto Depth is On!', 'warning');
      return false;
    }

    return true;
  };

  const handlePipes = () => {
    if (checkAutoTallyAndAutoDepth() && modalTxt === 'adding') {
      handleF2Key(autoTally);
    }
    if (checkAutoTallyAndAutoDepth() && modalTxt === 'removing') {
      handleF3Key(autoTally);
    }
  };

  const handleCancel = () => {
    setOpenPipeModal(false);
    setModalTxt('Adding');
  };

  const ModalTitle = () => (
    <p className="text-lg">
      <WarningOutlined style={{ color: '#F5D503', fontSize: 23, marginRight: 5 }} />
      {`${modalTxt} Pipe.`}
    </p>
  );

  return (
    <Modal
      open={openPipeModal}
      className="capitalize"
      title={<ModalTitle />}
      centered
      maskClosable={false}
      onOk={handlePipes}
      onCancel={handleCancel}
      afterOpenChange={(open) => {
        if (open && confirmButtonRef.current) {
          confirmButtonRef.current.focus();
        }
      }}
      okButtonProps={{
        style: { backgroundColor: '#3C8CA3' },
        ref: confirmButtonRef,
      }}
      okText="Confirm"
    >
      <p className="text-md capitalize">
        Please confirm <span className="text-md text-primary font-bold">{modalTxt}</span> Pipe.
      </p>
    </Modal>
  );
};

export default PipeController;
