import { Suspense } from 'react';
import { EmailIcon, Phone, Location } from 'components/icons';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import withAudibleAlert from 'utils/hoc/withAudibleAlert';
import LazyLoadingIndicator from 'components/shared/LazyLoadingIndicator';
import NavigationTop from './includes/NavigationTop';

function MainLayout({ shouldAlert }) {
  const settings = useSelector((state) => state.settings.settings);
  const path = window.location.pathname;
  const generalPath = path.includes('/about');
  const drillerMenu = path.includes('/driller-menu');
  const mainPath = path.includes('/home');
  // const mainDriller = path.includes('/driller');

  return (
    <div
      className={` bg-gray-100 text-primaryDark font-normal ${drillerMenu ? ' min-w-fit' : 'w-screen h-screen max-h-screen'} ${
        mainPath ? 'overflow-hidden' : 'overflow-auto'
      } space-y-2 ${generalPath ? '' : 'px-4 py-2'} dark:bg-darker dark:text-white`}
    >
      <div className={`${!generalPath ? '' : 'px-4 py-2'}`}>
        <NavigationTop path={generalPath} settingsApi={settings} shouldAlert={shouldAlert} />
      </div>
      <Suspense fallback={<LazyLoadingIndicator />}>
      <div className='h-[calc(100vh-8.35vh)]'>
        <Outlet />
        </div>
      </Suspense>
      {generalPath && (
        <footer className="w-full bg-white dark:bg-lightDark fixed bottom-0 py-6">
          <div className="w-full max-w-xl flex flex-col gap-y-2 justify-start items-start mx-auto">
            <div className="text-lg md:text-2xl mb-2">Contact us</div>
            <div className="flex items-center space-x-1 text-primary dark:text-white font-semibold">
              <Location color="#3C8CA3" />
              <p>Business Bay, Dubai, United Arab Emirates. </p>
            </div>
            <div className="flex items-center space-x-1 text-primary dark:text-white font-semibold">
              <EmailIcon color="#3C8CA3" />
              <a href="mailto:sales@saxondrilling.com">sales@saxondrilling.com</a>
            </div>
            <div className="flex items-center space-x-1 text-primary dark:text-white font-semibold">
              <Phone color="#3C8CA3" />
              <div>
                <a href="tel:+97145876552" className=" ml-1">
                  Tel.: +971 4 5876552
                </a>
              </div>
            </div>
          </div>
        </footer>
      )}
    </div>
  );
}

export default withAudibleAlert(MainLayout);
