import React from 'react';
import { useSelector } from 'react-redux';

const LogsSection = () => {
  const { logs } = useSelector((state) => state.drillerMenu);

  const spiltText = (message, color) => (
    <div className="flex items-center gap-x-1">
      <span>{message.split('#')[0]}</span>
      <span style={{ color }}>#{message.split('#')[1]}</span>
    </div>
  );

  return (
    <ul className="space-y-1 text-xs font-semibold pr-1 capitalize">
      {logs?.map((data) => (
        <li key={data.id} className="space-x-1">
          {spiltText(data.message, data.color)}
        </li>
      ))}
    </ul>
  );
};

export default LogsSection;
